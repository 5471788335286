import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => {
  return {
    sectionHeading: {
      padding: '0 0 3rem',
    },
    root: {
      position: 'relative',
      ...theme.shape.componentSpacing,
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(3),
      },
      '& .MuiTabs-flexContainer': {
        display: 'flex',
        borderBottom: '0',
        width: '100%',
        '&:hover .Mui-selected': {
          [theme.breakpoints.down('md')]: {
            borderColor: theme.mixins.Black(200),
          },
        },
      },
      '& .back-btn': {
        marginBottom: pxToRem(18),
        ...theme.mixins.left(pxToRem(-9)),
        width: '100%',
        padding: `0 ${theme.spacing(3)}`,
        position: 'absolute',
        top: 0,
        display: 'none',
        textTransform: 'capitalize',
        zIndex: 1,
        '& .MuiButton-label': {
          flexDirection: theme.mixins.flexDirection(),
        },
        '& .MuiButton-startIcon': {
          transform: theme.mixins.rotate180(),
          padding: '0',
          ...theme.mixins.marginLeft('0'),
        },
        '&.showBackBtn': {
          display: 'block',
        },
      },
    },
    disableGutter: {
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    tabContainer: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },

      '& .MuiTabs-root': {
        margin: '0',
        paddingLeft: '0',
        paddingRight: '0',
        marginTop: '0',
        alignItems: 'initial',
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.paddingRight(theme.spacing(3)),
        },
        '& .MuiTabs-indicator': {
          display: 'none',
        },
        '&.hideTabs': {
          display: 'none',
        },
      },
      '& > div': {
        transition: 'all 0.3s',
        paddingRight: theme.spacing(0),
        paddingLeft: theme.spacing(0),
        [theme.breakpoints.up('lg')]: {
          marginTop: '0',
        },
      },
      '& .tabPanelContainer': {
        width: '100%',
        marginTop: pxToRem(50),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        [theme.breakpoints.up('lg')]: {
          marginTop: '0',
        },
        '& h2': {
          paddingBottom: theme.spacing(2),
          [theme.breakpoints.up('lg')]: {
            padding: `${theme.spacing(4)} 0`,
          },
        },
        [theme.breakpoints.down('md')]: {
          display: 'none',
          '&.hideTabs': {
            display: 'block',
          },
        },
      },
      '& .MuiTab-root': {
        color: theme.mixins.Black(),
        ...theme.mixins.marginRight(0),
        display: 'flex',
        maxWidth: '100%',
        paddingTop: pxToRem(20),
        paddingBottom: pxToRem(20),
        ...theme.mixins.paddingLR(theme.spacing(3), theme.spacing(5)),
        borderTop: theme.borders.divider,
        textAlign: theme.mixins.textLeft,
        '&:last-child': {
          [theme.breakpoints.down('md')]: {
            borderBottom: theme.borders.divider,
          },
        },
        '& .MuiSvgIcon-root': {
          position: 'absolute',
          transform: theme.mixins.rotate0(),
          ...theme.mixins.right(theme.spacing(2)),
          top: `calc(50% - ${theme.spacing(1)})`,
          width: theme.spacing(2),
          height: theme.spacing(2),
          [theme.breakpoints.up('lg')]: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            top: `calc(50% - ${theme.spacing(1.5)})`,
            display: 'none',
          },
        },
        '& .MuiTab-wrapper': {
          alignItems: 'flex-start',
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(4),
          ...theme.mixins.paddingLR(theme.spacing(3), theme.spacing(5)),
          marginBottom: pxToRem(10),
          borderTop: 0,
          '&:hover': {
            backgroundColor: theme.mixins.Black(30),
            color: theme.mixins.AbuDhabiOrange(),
            '&:after': {
              width: '0',
              transform: 'none',
            },
            '& .MuiSvgIcon-root': {
              color: theme.mixins.Black(),
              [theme.breakpoints.up('lg')]: {
                display: 'block',
                color: theme.mixins.Black(),
              },
            },
          },
        },
        '& .MuiTypography-root': {
          ...theme.typography.body1Bold,
          textTransform: 'capitalize',
        },
        '&.Mui-selected': {
          [theme.breakpoints.up('lg')]: {
            color: theme.mixins.AbuDhabiOrange(),
            backgroundColor: theme.mixins.Black(30),
            '& .MuiSvgIcon-root': {
              display: 'block',
              color: theme.mixins.Black(),
            },
            '&:after': {
              width: '0',
              transform: 'none',
            },
          },
        },
        '&:after': {
          width: '0',
        },
      },
      '& .MuiTabs-scroller': {
        width: '100%',
      },
    },
    accordion: {
      '&:last-child': {
        [theme.breakpoints.down('md')]: {
          borderBottom: theme.borders.divider,
        },
      },
      '& .MuiGrid-root': {
        width: '100%',
      },
      '& .MuiExpansionPanelSummary-content': {
        margin: '0',
        '&.Mui-expanded': {
          margin: '0',
        },
      },
      '& .MuiExpansionPanelSummary-root': {
        padding: '0',
      },
      '& .MuiPaper-elevation1': {
        boxShadow: theme.shadows[0],
        borderTop: theme.borders.divider,
        '&:first-child': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        '&:last-child': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      },
      '& .MuiExpansionPanelDetails-root': {
        padding: `0 0 ${theme.spacing(3)}`,
        [theme.breakpoints.up('lg')]: {
          padding: `0 0 ${theme.spacing(6)}`,
        },
      },
      '& .MuiSvgIcon-root': {
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: theme.mixins.Black(),
        [theme.breakpoints.up('lg')]: {
          width: theme.spacing(4),
          height: theme.spacing(4),
        },
      },
    },
    accordionTitle: {
      padding: `${theme.spacing(3)} 0`,
      [theme.breakpoints.up('lg')]: {
        padding: `${theme.spacing(6)} 0`,
      },
    },
    accordionPanel: {
      padding: '0',
      color: theme.mixins.Grey(),
    },
  };
});

export default useStyles;

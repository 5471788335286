import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    '&.MuiTab-root': {
      ...theme.mixins.marginRight(pxToRem(20)),
      ...theme.mixins.marginLeft(pxToRem(0)),
      minWidth: 'auto',
      padding: 0,
      color: theme.palette.text.defaultText,
      minHeight: pxToRem(30),
      [theme.breakpoints.up('lg')]: {
        ...theme.mixins.marginRight(pxToRem(40)),
        ...theme.mixins.marginLeft(pxToRem(0)),
        position: 'relative',
      },
      '&.Mui-selected, &:hover': {
        color: theme.mixins.Black(),
      },
      '&:last-child': {
        ...theme.mixins.marginRight(0),
        ...theme.mixins.marginLeft(pxToRem(0)),
      },
    },
  },
}));

export default useStyles;

import React, { forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import get from 'lodash/get';
import TabMUI from './Tab';
import TabPanel from './TabPanel';
import useStyles from './style';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const CustomTabs = forwardRef((
  props,
  ref,
) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  const {
    tabs,
    className,
    textColor,
    tabIcon,
    variant,
    onTabChange,
    animateSettings = {},
    hideForSingle = false,
    ...other
  } = props;

  const { willAnimate = true, delay = 0 } = animateSettings;
  let tabsApperanceAnim;
  const customTabsElem = useRef(null),
    tabsWrapperRef = useRef(null);
  useImperativeHandle(ref, () => ({
    animPlay() {
      tabsApperanceAnim && tabsApperanceAnim.play();
    },
    animReverse() {
      tabsApperanceAnim && tabsApperanceAnim.reverse();
    },
  }));
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    setTimeout(() => {
      let haveAnimatedOnce = false;
      if (customTabsElem.current && willAnimate) {
        tabsApperanceAnim = gsap.fromTo(
          customTabsElem.current,
          {
            opacity: 0,
            y: '16px',
            ease: 'power1.out',
            paused: true,
          },
          {
            y: '0px',
            opacity: 1,
          },
          `+=${delay}`
        );
        tabsApperanceAnim.pause();
        gsap.timeline({
          scrollTrigger: {
            trigger: tabsWrapperRef.current,
            start: 'top bottom',
            end: `+=${(window.androidHeight || window.innerHeight) * 0.2}px bottom`,
            scrub: true,
            once: true,
            onUpdate: self => {
              if (self.progress > 0.15) {
                tabsApperanceAnim && tabsApperanceAnim.play();
                haveAnimatedOnce = true;
              } else if (haveAnimatedOnce) {
                tabsApperanceAnim && tabsApperanceAnim.reverse();
              }
            },
          },
        });
      }
    }, 100);
  }, []);
  if (!tabs) {
    return null;
  }
  const tabIdArr =
    tabs &&
    tabs.map(tab =>
      get(tab, 'label', '')
        .toLowerCase()
        .replace(/\s/g, '')
    );
  return (
    <div className={clsx(classes.root, className)} ref={tabsWrapperRef}>
      {!(hideForSingle && tabs.length < 2) &&
        <ConditionalWrapper
          condition={willAnimate}
          wrapper={children => (
            <div ref={customTabsElem}>{children}</div>
          )}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor={textColor}
            variant={variant}
            scrollButtons="auto"
            {...other}
          >
            {tabs &&
              tabs.map((tab, index) => (
                <TabMUI
                  key={index}
                  label={tab.label}
                  id={`tab-${tabIdArr[index]}-${index}`}
                  aria-controls={`tabpanel-${tabIdArr[index]}-${index}`}
                  icon={tabIcon}
                />
              ))}
          </Tabs>

        </ConditionalWrapper>
      }
      {tabs &&
        tabs.map((tab, index) => (
          <TabPanel
            key={index}
            value={value}
            index={index}
            dir={theme.direction}
            id={`tabpanel-${tabIdArr[index]}-${index}`}
            aria-labelledby={`tab-${tabIdArr[index]}-${index}`}
          >
            {tab.data}
          </TabPanel>
        ))}
    </div>
  );
});

CustomTabs.displayName = 'CustomTabs';

CustomTabs.propTypes = {
  textColor: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
};

CustomTabs.defaultProps = {
  textColor: 'primary',
  className: '',
  variant: 'scrollable',
  onTabChange: () => { },
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export default CustomTabs;

import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.transparent,

    '& .MuiTabs-root': {
      marginTop: pxToRem(24),
      marginBottom: pxToRem(24),
      minHeight: pxToRem(30),
      alignItems: 'center',

      [theme.breakpoints.up('lg')]: {
        marginTop: pxToRem(48),
        marginBottom: pxToRem(32),
      },

      '& .MuiTabs-indicator': {
        background: theme.mixins.AbuDhabiOrange(),
        height: pxToRem(4),
        transitionDuration: '500ms',
      },
    },

    '& .MuiTabs-flexContainer': {
      transition: 'border-color .5s ease',
      display: 'inline-block',
      width: 'max-content',
      '&:hover': {
        '& .Mui-selected': {
          borderColor: 'transparent',
        },
      },
    },
    '&.pills-tabs-container': {
      '& .MuiTabs-root': {
        '& .MuiTab-root': {
          padding: `${pxToRem(10)} ${pxToRem(23.5)}`,
          ...theme.mixins.marginRight(pxToRem(9.5)),
        },
        '& .Mui-selected': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.common.SeaGreen,
          borderRadius: borderRadius.b50px,
        },
        '& .MuiTabs-indicator': {
          background: theme.palette.background.transparent,
          height: 0,
          transitionDuration: '0',
        },

        '& .MuiTypography-body2': {
          ...theme.palette.common.ellipsis(1),
        }
      },
    }
  },

}));

export default useStyles;

import React, { useEffect, useState } from 'react';
import CustomTabs from '../../../components/molecules/Tabs';
import Grid from '../../../components/molecules/Grid';
import Typography from '../../../components/atoms/Typography';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import useStyles from './style';
import DctSvgIcon from '../../../components/atoms/Svg';
import clsx from 'clsx';
import Accordion from '../../../components/atoms/Accordion';
import { canUseDOM } from '../../../utils/canUseDOM';
import ButtonMUI from '../../../components/atoms/Button';
import { COMPONENT_NAME } from './locators';
import { get } from 'lodash';
import RichText from '../../../components/atoms/RichText';

const FaqAccordion = ({ fields, t: dictionary }) => {
  const faqCategoryList = fields.faqCategoryList;
  const faqCategories = Array.isArray(faqCategoryList) ? faqCategoryList : Object.keys(fields).length > 0 ? [{ fields }] : []
  const classes = useStyles();

  const backButtonText = (dictionary && dictionary('backButton')) || '';

  const [expanded, setExpanded] = useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [dimensions, setDimensions] = React.useState({
    height: 0,
    width: 0,
  })
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: (window.androidHeight || window.innerHeight),
        width: window.innerWidth,
      })

    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)

    }
  }, [])
  useEffect(() => {
    if (canUseDOM) {
      const accordionTitle = document.querySelectorAll(
        '[data-locator="FaqAccordion"]  .MuiExpansionPanelSummary-root[aria-expanded="false"]'
      );
      const isMobile = window.matchMedia('(max-width: 1023px)');
      const titleHeading = isMobile.matches ? 64 : 128;
      const headerElemt = document.querySelector('header.mui-fixed');
      const headerHeight = headerElemt ? headerElemt.clientHeight : 0;

      accordionTitle &&
        accordionTitle.forEach(item =>
          item.addEventListener('click', () => {
            setTimeout(() => {
              const scrollTopPos =
                item.getBoundingClientRect().top +
                window.pageYOffset -
                titleHeading -
                headerHeight;
              window.scroll({
                top: scrollTopPos,
                left: '0',
                behavior: 'smooth',
              });
            }, 200);
          })
        );
    }
  });

  useEffect(() => {
    const isMobile = canUseDOM && window.matchMedia('(max-width: 1023px)');

    if (canUseDOM && isMobile.matches) {
      const tab = document.querySelectorAll('[data-locator="FaqAccordion"] .MuiTab-root');
      const tabContainer = document.querySelector('[data-locator="FaqAccordion"] .MuiTabs-root');
      const panelContainer = document.querySelectorAll(
        '[data-locator="FaqAccordion"]  .tabWrapper > .tabPanelContainer'
      );
      const backBtnContainer = document.querySelector('.back-btn');
      const backBtn =
        backBtnContainer && backBtnContainer.querySelector('span');

      if (faqCategories.length > 1) {
        tab &&
          tab.forEach((item, i) =>
            item.addEventListener('click', () => {
              tabContainer && tabContainer.classList.add('hideTabs');
              panelContainer && panelContainer[i].classList.add('hideTabs');
              backBtnContainer && backBtnContainer.classList.add('showBackBtn');
            })
          );
        backBtn &&
          backBtn.addEventListener('click', () => {
            tabContainer && tabContainer.classList.remove('hideTabs');
            panelContainer &&
              panelContainer.forEach(item => item.classList.remove('hideTabs'));
            backBtnContainer && backBtnContainer.classList.remove('showBackBtn');
          });
      } else if (faqCategories.length === 1) {
        panelContainer[0].classList.add('hideTabs')
      }
    }
  }, [dimensions]);

  const closeAccordion = () => {
    setExpanded(false);
    if (canUseDOM) {
      const tabWrapper = document.querySelector('.tabWrapper');
      const scrollTopPos =
        tabWrapper.getBoundingClientRect().top + window.pageYOffset - 150;

      window.scroll({
        top: scrollTopPos,
        left: '0',
        behavior: 'smooth',
      });
    }
  };

  const tabData = (data, title, tabDescription) => {
    return (
      <React.Fragment>
        <div
          className={clsx('section-heading', { [classes.sectionHeading]: tabDescription })}
        >
          <Typography variant="h5Bold" component="h2" gutterBottom={false}>
            <Text field={{ value: title }} />
          </Typography>

          <Typography
            variant={'body1'}
            component="p"
            gutterBottom={false}
          >
            <RichText innerHTML={{
              value: tabDescription,
            }} />
          </Typography>
        </div>
        {data &&
          data.length > 0 &&
          data.map((tabItem, index) => {
            return (
              <Accordion
                className={classes.accordion}
                key={index}
                tabIdentifier={index}
                expanded={expanded}
                handleChange={handleChange}
              >
                <div className={classes.accordionTitle}>
                  <Typography
                    variant={'body1Bold'}
                    component="h3"
                    gutterBottom={false}
                  >
                    <Text field={get(tabItem, 'fields.question', '')} />
                  </Typography>
                </div>
                <div className={classes.accordionPanel}>
                  <Typography
                    variant={'body1'}
                    component="p"
                    gutterBottom={false}
                  >
                    {tabItem.fields.answer && (
                      <RichText innerHTML={tabItem.fields.answer} />
                    )}
                  </Typography>
                </div>
              </Accordion>
            );
          })}
      </React.Fragment>
    );
  };

  const createTabs =
    faqCategories &&
    faqCategories.length > 0 &&
    faqCategories.map(tab => {
      const tabTitle = get(tab, 'fields.categoryTitle.value', '');
      const tabDescription = get(tab, 'fields.categoryDescription.value', '');
      const tabPanelData = get(tab, 'fields.faqList', '');

      return {
        data: tabData(tabPanelData, tabTitle, tabDescription),
        label: tabTitle,
      };
    });

  return (
    <div
      className={clsx(classes.root, 'FaqAccordion')}
      data-locator={COMPONENT_NAME}
    >
      <Grid classes={{ root: classes.disableGutter }}>
        <Grid container>
          <div className="back-btn" onClick={closeAccordion}>
            <ButtonMUI
              component="span"
              buttonType="secondary"
              hasBorder={false}
              isStartIcon={true}
            >
              {backButtonText}
            </ButtonMUI>
          </div>
          <CustomTabs
            tabs={createTabs}
            hideForSingle={true}
            tabIcon={<DctSvgIcon name="ArrowForwardIosSharp"></DctSvgIcon>}
            className={clsx(classes.tabContainer, 'tabWrapper')}
            orientation="vertical"
            onClick={closeAccordion}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(FaqAccordion))
);

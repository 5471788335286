import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      className="tabPanelContainer"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  timeout: PropTypes.number,
};

TabPanel.defaultProps = {
  timeout: 800,
};

export default TabPanel;

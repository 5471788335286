import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import DctSvgIcon from '../Svg';
import Grid from '../../molecules/Grid';

const Accordion = props => {
  const { className, tabIdentifier, handleChange, expanded } = props;

  return (
    <Grid container classes={{ root: className }}>
      <Grid item>
        <ExpansionPanel
          expanded={expanded === tabIdentifier}
          onChange={handleChange(tabIdentifier)}
        >
          <ExpansionPanelSummary
            expandIcon={<DctSvgIcon name="ExpandMoreIcon" />}
            aria-controls={`panel${tabIdentifier}-content`}
            id={`panel${tabIdentifier}-header`}
          >
            {props.children[0]}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>{props.children[1]}</ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  );
};

export default Accordion;

import React from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Typography from '../../../atoms/Typography';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import Button from '@material-ui/core/Button';

import useStyles from './style';

const TabMUI = ({ label, ...other }) => {
  const isEditor = isExperienceEditorActive();

  const classes = useStyles();
  const typography = (
    <Typography variant="body2" component="span">
      <Text field={{ value: label }} />
    </Typography>
  );

  return <Tab className={classes.root} label={typography} {...other} />;
};

TabMUI.propTypes = {
  label: PropTypes.node.isRequired,
  disableRipple: PropTypes.bool,
};

TabMUI.defaultProps = {
  disableRipple: true,
};

export default TabMUI;
